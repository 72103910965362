<template>
  <b-container class="bg-light page-wrapper sandbox-period-selector-example">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col> </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">Period selector version 2 demo</h1>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="12" md="7">Period selector example</b-col>
        <b-col cols="12" md="5">
          <period-selector
            v-if="isPeriodSelectorReady"
            :show-comparison-period="true"
            :show-last-months="true"
            :show-last-years="true"
            :show-resolution="true"
            :show-period-option-full-period="true"
            :load-default-state-from-storage="true"
            :default-start-date="defaultStartDate"
            :default-end-date="defaultEndDate"
            default-resolution="year"
            @period-selected="onPeriodSelected"
          />
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h3>Date and time of last emit:</h3>
          <pre>{{ lastEmittedTime }}</pre>
        </b-col>
      </b-row>
      <b-row no-gutters class="mt-4">
        <b-col>
          <h3>Emitted data:</h3>
          <pre>{{ periodSelectorData }}</pre>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import PeriodSelector from '@/components/common/PeriodSelector.vue'

export default {
  name: 'PeriodSelectorExample',
  components: { PeriodSelector },
  data () {
    return {
      isPeriodSelectorReady: false,
      periodSelectorData: null,
      lastEmittedTime: 'Not emitted yet.',
      defaultStartDate: null,
      defaultEndDate: null
    }
  },
  methods: {
    onPeriodSelected (eventData) {
      this.periodSelectorData = eventData
      this.lastEmittedTime = (new Date()).toISOString().substring(0, 19).replace('T', ' ')
    }
  },
  mounted () {
    this.defaultStartDate = this.$route.query.start_date ?? null
    this.defaultEndDate = this.$route.query.end_date ?? null
    this.isPeriodSelectorReady = true
  }
}
</script>

<style lang="scss">
.sandbox-period-selector-example {
  pre {
    font-size: 80%;
  }
}
</style>
